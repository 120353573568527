<template>
    <div class="mobile-page-layout">
        <div class="mobile-page-header" style="position: relative;z-index: 10;background: #fff;border-bottom: 1px solid #e8e8e8;">
            <div class="mobile-page-header-row">
                <div class="mobile-page-header-right">
                    <router-link :to="{ name: 'HomeIntro'}" class="mobile-page-header-icon pointer back-icon">
                        <i class="header-uil-icon uil uil-angle-right-b"></i>
                    </router-link>
                    <div class="mobile-page-header-icon">
                        <div class="mobile-page-header-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path class="fa-primary" d="M640 191.1v191.1c0 35.25-28.75 63.1-64 63.1h-32v54.24c0 7.998-9.125 12.62-15.5 7.873l-82.75-62.12L319.1 447.1C284.7 447.1 256 419.2 256 383.1v-31.98l96-.002c52.88 0 96-43.12 96-95.99V128h128C611.3 128 640 156.7 640 191.1z"></path><path class="fa-secondary" d="M352 0H64C28.75 0 0 28.75 0 63.1V256C0 291.2 28.75 320 64 320l32 .0098v54.25c0 7.998 9.125 12.62 15.5 7.875l82.75-62.12L352 319.9c35.25 .125 64-28.68 64-63.92V63.1C416 28.75 387.3 0 352 0z"></path></svg>
                        </div>
                    </div>
                    <div class="mobile-page-header-text">پشتیبانی هوشمند</div>
                </div>
            </div>
        </div>
        <div class="home-layout with-header" style="padding: 0px; overflow: hidden;height: calc(100% + 32px);top: -32px;">
            <iframe class="iframe-chat-mobile" style="height: 100%; border: 0px none;" src="https://www.tgju.org/components/side-panel/smart-chat.php?043"></iframe>
        </div>

    </div>
</template>

<script>
    export default {
        name: 'SmartChat',
        components: {},
        data: function () {
            return {
            }
        },
        mounted() {},
        computed: {},
        methods: {},
    }
</script>